@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
}

#graphic-basketball-shape-1,
#graphic-basketball-shape-2,
#graphic-football-shape-1,
#graphic-football-shape-2,
#graphic-volleyball-shape-1,
#graphic-volleyball-shape-2,
#graphic-tennis-shape-1,
#graphic-tennis-shape-2,
#graphic-tennis-shape-3,
#graphic-tennis-shape-4,
#graphic-other-shape-1,
#graphic-other-shape-2
{
  @apply fill-action-platinum
}

#graphic-basketball-shape-3, #graphic-football-shape-3, #graphic-volleyball-shape-3, #graphic-tennis-shape-5, #graphic-other-shape-3 {
  @apply opacity-0
}

#action-image-dashed-circle {
  display: block !important;
}

#action-image mask, #action-image path, #action-image mask,#action-image rect,#action-image g,#action-image circle,#action-image filter {
  display: none;
}

.animation-zoom-volleyball {
  animation: zoom 1s ease 0s 1 normal forwards;
}

.animation-zoom-tennis {
  animation: zoom 1s ease 0.5s 1 normal forwards;
}

.animation-zoom-football {
  animation: zoom 1s ease 1s 1 normal forwards;
}

.animation-zoom-other {
  animation: zoom 1s ease 1.5s 1 normal forwards;
}

.animation-zoom-basketball {
  animation: zoom 1s ease 2s 1 normal forwards;
}

.animation-zoom-documents {
  animation: zoom 1s ease 2.5s 1 normal forwards;
}

@media (min-width: 1023px) {
  .animation-fade {
    animation: fade 1s ease 0s 1 normal forwards;
  }

  .animation-zoom {
    animation: zoom 1s ease 0s 1 normal forwards;
  }
}

@keyframes zoom {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.iframe-container-big {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 45%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
